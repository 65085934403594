import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Icon, IconButton, LinearProgress, TextField } from '@mui/material'

import { addPractitionerBussiness } from '../../Actions/Practitioner/ProfileDetailingAction';
import { toast } from 'react-toastify';

const ProfileDetailAddress = ({setProfileDetails}) => {
  const dispatch = useDispatch();
    const [online, setOnline] = useState(false);
    const [offline, setOffline] = useState(false);
    const [address, setAddress] = useState("");
    const [description, setDescription] = useState("");
    const profileDetailingState = useSelector((state) => state.profileDetailing);
    
    const handleOnlineChange = () => {
      setOnline(!online);
    };
  
    const handleOfflineChange = () => {
      setOffline(!offline);
    };
   
    const handleAddressChange = (event) => {
      setAddress(event.target.value);
    };
  
    const handleDescriptionChange = (event) => {
      setDescription(event.target.value);
    };
   
    const changeScreen2 = () => {
        setProfileDetails(5);
      };
      const backtoPrev2 = () => {
        setProfileDetails(4);
      };
      const handleNextClick1 = async () => {
        if (
          (offline && (address == "")) ||
          description == "" ||
          (online === false && offline === false)
        ) {
          toast.error("Please fill in the details", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          let session;
          if (online && offline) {
            session = 2;
          } else if (online) {
            session = 0;
          } else if (offline) {
            session = 1;
          }
          const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          const body = {
            userId: user.user.id,
            sessionType: session,
            address: address,
            description: description,
            timeZone: timeZone,
          };
          dispatch(addPractitionerBussiness(body));
        }
      };
  
  return (
   <>
    <Box
      className="self-stretch h-[11px] relative mix-blend-normal"
      sx={{ width: "100%", marginTop: "5px" }}
    >
      <LinearProgress
        variant="determinate"
        value={66}
        sx={{ height: 10, borderRadius: 5 }}
      />
    </Box>

    <div className="self-stretch flex flex-row flex-wrap items-start justify-start max-w-full [row-gap:20px] text-13xl">
      <div className="flex-1 flex flex-col items-start justify-start min-w-[349px] max-w-full mq675:min-w-full">
        <h1 className="m-0 self-stretch relative text-inherit leading-[48px] font-bold font-inherit mq450:text-lgi mq450:leading-[29px] mq750:text-7xl mq750:leading-[38px]">
          Add Description
        </h1>
        <div className="self-stretch relative text-sm leading-[22px]"></div>
      </div>
      <div className="rounded overflow-hidden flex flex-row items-start justify-start py-1 px-4 gap-[10px]">
        <div className="bg-hitbox-1000 overflow-hidden hidden flex-col items-center justify-center">
          <img
            className="w-3.5 h-3.5 relative overflow-hidden shrink-0 object-cover"
            alt=""
            src="/wrapper1@2x.png"
          />
        </div>
        <Button
          className="relative min-w-[83px]"
          disableElevation={true}
          variant="text"
          sx={{ borderRadius: "0px 0px 0px 0px" }}
          onClick={changeScreen2}
        >
          Skip for now
        </Button>
      </div>
    </div>

    <div className="self-stretch flex flex-col items-start justify-start gap-[16px] text-base flex-grow">
      <div className="relative leading-[24px] font-semibold inline-block min-w-[105px]">
        Session Type:
      </div>
      <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[24px]">
        <div className="flex-1 flex flex-row items-start justify-start gap-[16px] min-w-[204px]">
          <input
            className="m-0 h-6 w-6 relative rounded overflow-hidden shrink-0 min-h-[24px]"
            type="checkbox"
            checked={online}
            onChange={handleOnlineChange}
          />
          <div className="flex-1 relative leading-[24px]">Online</div>
        </div>
        <div className="flex-1 flex flex-row items-start justify-start gap-[16px] min-w-[204px] mq450:flex-wrap">
          <input
            className="m-0 h-6 w-6 relative rounded overflow-hidden shrink-0"
            type="checkbox"
            checked={offline}
            onChange={handleOfflineChange}
          />
          <div className="flex-1 relative leading-[24px] inline-block min-w-[45px]">
            On-site
          </div>
        </div>
      </div>
    </div>

    <div className="self-stretch h-[47px] flex flex-row items-start justify-start pt-0 px-0 pb-0 box-border max-w-full text-text-secondary font-components-helper-text">
      <TextField
        className="[border:none] bg-[transparent] h-[57px] flex-1 font-web-secondary-caption text-base text-m3-ref-neutral-neutral20-200 min-w-[250px] max-w-full"
        color="primary"
        label="Address"
        fullWidth={true}
        variant="standard"
        value={address}
        disabled={!offline}
        onChange={handleAddressChange}
        sx={{ "& .MuiInputBase-root": { height: "57px" } }}
      />
      <div className="h-5 w-[220px] hidden flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border">
        <div className="self-stretch relative tracking-[0.4px] leading-[166%]">
          Helper text
        </div>
      </div>
    </div>

    <div className="self-stretch flex flex-row items-start justify-start max-w-full text-text-secondary">
      <TextField
        className="flex-1 font-web-secondary-caption text-base text-m3-ref-neutral-neutral20-200 max-w-full"
        color="primary"
        rows={5}
        label="Description"
        variant="standard"
        multiline
        value={description}
        onChange={handleDescriptionChange}
      />
    </div>
    <div className="flex flex-row items-center justify-between gap-[16px] mt-auto">
      <IconButton
        className="h-[50px] w-[50px] shadow-[0px_2px_8px_rgba(0,_0,_0,_0.15)]"
        sx={{ borderRadius: "0px 0px 0px 0px" }}
        color="primary"
        onClick={backtoPrev2}
      >
        <Icon>arrow_back_ios_sharp</Icon>
      </IconButton>
      <Button
        className="h-[60px] flex-1 min-w-[670px] max-w-[570px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)] mq675:min-w-full mq450:pl-5 mq450:pr-5 mq450:box-border"
        variant="contained"
        onClick={handleNextClick1}
        sx={{
          textTransform: "none",
          color: "#fff",
          fontSize: "18px",
          background: "#006a6a",
          border: "#006a6a solid 1px",
          borderRadius: "8px",
          "&:hover": { background: "#006a6a" },
          height: 60,
        }}
      >
         {profileDetailingState.profileDetailing === "isLoading" ? (
          <div className="mt-2">
            <Loader />
          </div>
        ) : profileDetailingState?.profileDetailing?.status === true ? (
          navigateToNext3()
        ) : (
          "Next"
        )}
      </Button>
    </div>
  
   </>
  )
}

export default ProfileDetailAddress